import React from 'react'
import { FacebookShareButton, TwitterShareButton, } from 'react-share'
import './Share.scss'

const Share = ({ socialConfig, tags }) => (
	<div className="post-social">
		<span>Share on</span>
		<FacebookShareButton 
			url={socialConfig.config.url} 
			className="button is-outlined is-rounded facebook"
		>
			<i className="fab fa-facebook-f icon"></i>
			<span className="text">Facebook</span>
		</FacebookShareButton>
		<TwitterShareButton 
			url={socialConfig.config.url} 
			className="button is-outlined is-rounded twitter" 
			title={socialConfig.config.title} 
			via={socialConfig.twitterHandle} 
			hashtags={tags}
		>
			<i className="fab fa-twitter icon"></i>
			<span className="text">Twitter</span>
		</TwitterShareButton>
	</div>
)

export default Share
